import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="THE PAINTING BOX" />
    <div style={{ marginTop: `5.45rem` }}>
      <h3>I smile and shake my head and say my little ship is sinking </h3>
      <h3>But I kind of like the sea that I'm on and I don't mind if I do drown </h3>
      <a style={{ color: 'gray' }} href="https://www.youtube.com/watch?v=UoQ3tmohEX4">The Painting Box - Incredible String Band</a>
    </div>
  </Layout>
)

export default IndexPage
